import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  label?: string
  title?: string
  URL: string
}

export const Service = memo(function Service({
  image,
  label,
  title,
  URL,
}: Props) {
  if (!URL) {
    return null
  }

  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  const componentDOM = (
    <>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      {title ? (
        <Wrapper className="service-wrapper">
          {label ? <Label>{label}</Label> : null}

          <Title>{title}</Title>
        </Wrapper>
      ) : null}
    </>
  )

  return (
    <>
      {externalURL ? (
        <ExternalLink href={URL} rel="noopener" target="_blank">
          {componentDOM}
        </ExternalLink>
      ) : (
        <Container to={URL || ''}>{componentDOM}</Container>
      )}
    </>
  )
})

const Style = css`
  width: 50%;
  aspect-ratio: 1.2;
  overflow: hidden;
  position: relative;
  text-align: left;
  &:hover {
    &:before {
      opacity: 0;
    }
    img {
      transform: scale(1.1);
    }
    .service-wrapper {
      background-color: ${theme.colors.variants.primaryDark3};
      border-color: transparent;

      > div {
        color: ${theme.colors.variants.primaryLight1};
      }
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${theme.colors.variants.primaryDark1};
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1);
    transition: all 0.3s ease-in-out !important;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const ExternalLink = styled.a`
  ${Style}
`

const Container = styled(Link)`
  ${Style}
`

const Wrapper = styled.div`
  max-width: 21.25rem;
  border-right: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight4};
  border-top: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 3.75rem;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  z-index: 3;

  @media (max-width: 1199px) {
    max-width: none;
    border: none;
    padding: 0 1.875rem 1.875rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  margin-bottom: 0.6875rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
`

const Title = styled.h4`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.875rem;
`
