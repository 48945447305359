import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
  subtitle?: string
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  services,
  subtitle,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container>
      <Head>
        {title ? (
          <FadeInUp>
            <Title className="wave-dark">{title}</Title>
          </FadeInUp>
        ) : null}

        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}
      </Head>

      <Services row wrap>
        {services.map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section``

const Head = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 7.875rem 1.875rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
`

const Title = styled.h2`
  max-width: 32.375rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  line-height: 4.3125rem;
  margin: auto;
  &::after {
    margin-top: 0.75rem !important;
  }

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 2.5rem;
    line-height: 2.8125rem;
    &::after {
      display: none !important;
    }
  }
`

const Subtitle = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  margin-top: 1.875rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    margin-top: 1.25rem;
  }
`

const Services = styled(FlexBox)``
