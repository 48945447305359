import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  description?: string
  subtitle?: string
  title?: string
}

export const Content = memo(function Content({
  cta,
  description,
  subtitle,
  title,
}: Props) {
  return (
    <Container>
      <Head dial={4} row wrap>
        <LeftSide>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}

          {subtitle ? (
            <FadeInUp>
              <Subtitle>{subtitle}</Subtitle>
            </FadeInUp>
          ) : null}
        </LeftSide>

        <Wave className="wave" />

        <Logo
          alt="Riviera dei fiori"
          src="logo-riviera.svg"
          width="73"
          height="86"
        />
      </Head>

      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}

      {cta ? (
        <FadeInUp>
          <CTA {...cta} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  margin-top: 3.75rem;
  padding: 0 12.639vw;

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
    padding: 0 1.875rem;
  }
`

const Head = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
`

const LeftSide = styled.div`
  @media (max-width: 1199px) {
    order: 2;
  }
`

const Wave = styled.div`
  flex: 1;
  margin: 0 3.75rem;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.75rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.8125rem;
  }
`

const Subtitle = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  margin-top: 1.25rem;
  text-transform: uppercase;
`

const Logo = styled.img`
  @media (max-width: 1199px) {
    display: block;
    margin-bottom: 0.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 3.75rem;

  @media (max-width: 1199px) {
    font-size: 16px;
    margin-top: 2.5rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3.125rem;
`
