import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Props as ButtonProps } from 'app/components/Common/Button'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

import { Card, Props as CardProps } from './Card'
import { Content } from './Content'

export interface Props {
  cards: CardProps[]
  cta?: ButtonProps
  description?: string
  subtitle?: string
  title?: string
}

export const LocationPreview = memo(function LocationPreview({
  cards,
  cta,
  description,
  subtitle,
  title,
}: Props) {
  if (!cards) {
    return null
  }

  const variants = {
    visible: (i: any) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.3,
        duration: 0.6,
        stiffness: 1000,
        velocity: -100,
      },
    }),
    hidden: { opacity: 0, x: -20 },
  }

  const [headRef, isHeadVisible] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  })

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    slides: {
      perView: 3,
      spacing: 58,
    },
    breakpoints: {
      '(max-width: 1023px)': {
        slides: {
          perView: 1.2,
          spacing: 24,
        },
      },
    },
  })

  return (
    <Container>
      <Head ref={headRef}>
        <Slider className="grab-cursor" ref={sliderRef}>
          {uniq(cards).map((item, index) => (
            <Item key={index} className="keen-slider__slide">
              <Card
                animate={isHeadVisible ? 'visible' : 'hidden'}
                custom={index}
                variants={variants}
                {...item}
              />
            </Item>
          ))}
        </Slider>

        {cards.length > 3 ? (
          <Arrows>
            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
            />

            <Arrow
              direction="R"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
            />
          </Arrows>
        ) : null}
      </Head>

      <Content
        cta={cta}
        description={description}
        subtitle={subtitle}
        title={title}
      />
    </Container>
  )
})

const Container = styled.section``

const Head = styled.div`
  padding: 11.375rem 12.639vw 0;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: calc(50% + 5.6875rem);
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    padding: 7.5rem 1.875rem 0;
  }
`

const Slider = styled.div`
  display: flex;
  outline: none;
  overflow: hidden;

  @media (max-width: 1199px) {
    overflow: visible;
  }
`

const Item = styled.div``

const Arrows = styled.div`
  > div {
    position: absolute;
    top: calc(50% + 5.6875rem);
    transform: translateY(-50%);
    &:first-of-type {
      left: 6.806vw;
    }
    &:last-of-type {
      right: 6.806vw;
    }
  }

  @media (max-width: 1199px) {
    display: none;
  }
`
