import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Facebook, Instagram } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  facebookURL?: string
  images: ImageProps[]
  languageCode: string
  instagramURL?: string
}

export const SocialWall = memo(function SocialWall({
  facebookURL,
  images,
  languageCode,
  instagramURL,
}: Props) {
  if (images.length < 1) {
    return null
  }

  return (
    <Container>
      <Head dial={4} row wrap>
        <Wrapper>
          <Hashtag>#hotelolympic</Hashtag>

          <Label>{useVocabularyData('follow-us', languageCode)}</Label>
        </Wrapper>

        <Wave className="wave" />

        <Social row wrap>
          {facebookURL ? (
            <Anchor
              aria-label="Facebook"
              href={facebookURL}
              rel="noreferrer"
              target="_blank"
            >
              <Facebook />
            </Anchor>
          ) : null}

          {instagramURL ? (
            <Anchor
              aria-label="Instagram"
              href={instagramURL}
              rel="noreferrer"
              target="_blank"
            >
              <Instagram />
            </Anchor>
          ) : null}
        </Social>
      </Head>

      <Images>
        {images.map((item, index) => (
          <Background key={index}>
            <LazyLoadComponent>
              <Image {...item} />
            </LazyLoadComponent>
          </Background>
        ))}
      </Images>
    </Container>
  )
})

const Container = styled.section`
  @media (max-width: 767px) {
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    padding-bottom: 1.875rem;
  }
`

const Head = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 5rem 12.639vw 9.125rem;

  @media (max-width: 1199px) {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }

  @media (max-width: 767px) {
    display: block;
    padding-bottom: 0;
  }
`

const Wave = styled.div`
  flex: 1;
  margin: 0 3.75rem;

  @media (max-width: 767px) {
    display: none;
  }
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
`

const Hashtag = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.875rem;
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  margin-top: 0.625rem;
  text-transform: uppercase;
`

const Social = styled(FlexBox)`
  @media (max-width: 767px) {
    margin-top: 1.25rem;
  }
`

const Anchor = styled.a`
  margin-left: 1.875rem;
  &:first-of-type {
    margin-left: 0;
  }
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.variants.neutralLight2};
    }
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.primaryDark3};
    transition: 0.25s ease-in-out;
  }

  @media (max-width: 767px) {
    margin-right: 1.25rem;
    margin-left: 0;
  }
`

const Images = styled.div`
  overflow: hidden;
  margin: -5.625rem -1.5rem 0 0;
  padding: 0 12.639vw;

  @media (max-width: 1199px) {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }

  @media (max-width: 767px) {
    margin: 1.875rem -0.375rem 0 0;
  }
`

const Background = styled.div`
  width: calc(16.666% - 1.5rem);
  float: left;
  margin-right: 1.5rem;
  padding-bottom: calc(16.666% - 1.5rem);
  position: relative;
  &:nth-of-type(1),
  &:nth-of-type(2) {
    width: calc(33.333% - 1.5rem);
    padding-bottom: calc(33.333% - 1.5rem);
  }
  &:nth-of-type(5),
  &:nth-of-type(6) {
    margin-top: 1.5rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    width: calc(16.666% - 0.375rem);
    margin-right: 0.375rem;
    padding-bottom: calc(16.666% - 0.375rem);
    &:nth-of-type(1),
    &:nth-of-type(2) {
      width: calc(33.333% - 0.375rem);
      padding-bottom: calc(33.333% - 0.375rem);
    }
    &:nth-of-type(5),
    &:nth-of-type(6) {
      margin-top: 0.375rem;
    }
  }
`
