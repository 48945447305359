import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  animate?: any
  className?: string
  custom?: any
  image?: ImageProps
  title?: string
  variants?: any
}

export const Card = memo(function Card({
  animate,
  className,
  custom,
  image,
  title,
  variants,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container
      animate={animate}
      className={className}
      custom={custom}
      variants={variants}
    >
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      <Title>{title}</Title>
    </Container>
  )
})

const Container = styled(motion.div)`
  aspect-ratio: 0.7;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.primaryDark1, 0)},
      ${rgba(theme.colors.variants.primaryDark1, 0.6)}
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.875rem;
  position: absolute;
  right: 2.5rem;
  bottom: 2.5rem;
  left: 2.5rem;
  z-index: 3;

  @media (max-width: 1199px) {
    right: 1.875rem;
    bottom: 1.875rem;
    left: 1.875rem;
  }
`
