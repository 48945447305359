import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo, useEffect, useRef, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  cta?: ButtonProps
  image?: ImageProps
  services: ServiceProps[]
  title?: string
}

export const VisualServices = memo(function VisualServices({
  cta,
  image,
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  const servicesWrap = useRef<any>()
  const [dimesion, setDimension] = useState(0)

  const onResize = React.useCallback(() => {
    if (servicesWrap.current)
      setDimension(servicesWrap.current.clientHeight / 2)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <Container>
      <Wrapper>
        {image ? (
          <Background style={{ height: `calc(100% + ${dimesion}px)` }}>
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          </Background>
        ) : null}

        <Mask style={{ height: `calc(100% + ${dimesion}px)` }} />

        {title ? (
          <Title className="wave-split">
            <Inner>{title}</Inner>
          </Title>
        ) : null}
      </Wrapper>

      <Services ref={servicesWrap}>
        <List row wrap>
          {services.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </List>
      </Services>

      {cta ? (
        <FadeInUp>
          <CTA {...cta} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-top: 10rem;
  padding-bottom: 3.75rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin-top: 5.625rem;
    padding-bottom: 0;
  }
`

const Wrapper = styled.div`
  padding: 8.875rem 16.458vw 5rem;
  position: relative;

  @media (max-width: 1199px) {
    padding: 90px 30px 30px;
  }
`

const Background = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Mask = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
`

const Title = styled.h2`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.8125rem;
  margin: auto;
  position: relative;
  z-index: 2;
  &::before,
  &::after {
    opacity: 0.3;
  }

  @media (max-width: 1199px) {
    &::before,
    &::after {
      display: none;
    }
  }
`

const Inner = styled.div`
  max-width: 18.75rem;
  margin: 0 5.3125rem;

  @media (max-width: 1199px) {
    max-width: none;
    margin: auto;
  }
`

const Services = styled.div`
  padding: 0 16.458vw;

  @media (max-width: 1199px) {
    padding: 0 1.875rem;
  }
`

const List = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: auto;
  padding: 2.1875rem 11.458vw 6.5625rem;
  position: relative;
  z-index: 2;

  @media (max-width: 1199px) {
    padding: 0 1.25rem 3.75rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3.75rem;
`
